// @ts-nocheck
import React from 'react'

import Layout from '../../components/Layout'
import SEO from '../../components/seo'
import { Router } from '@reach/router'
// import PostDetail from '../../components/PostDetail'
// import Post from '../../components/Post'

export default () => (
  <Layout>
    <SEO title="Home" />
    <Router>
      {/*<PostDetail path={`${ROUTES.POST}/:slug`} />*/}

      {/*<CreatePostButton path={`${ROUTES.POST}`} />*/}
      {/*<Post path={`${ROUTES.POST}`} />*/}
    </Router>
  </Layout>
)
